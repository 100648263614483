import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { environment } from "../../../environments/environment";
import { HttpHeaders } from "@angular/common/http";
import { EnvService } from "../env/env.service";

@Injectable({
  providedIn: "root",
})
export class ApiWalletService {
  apiToken;
  constructor(private apiService: ApiService) {
    this.apiToken = sessionStorage.getItem("walletToken");
  }

  authCheck() {
    let url = `authcheck`;
    const options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.apiToken}`,
      }),
    };
    return this.apiService.get(environment.mainApiUrl, url, options);
  }

  preAuth(data) {
    let url = `preauth`;
    const options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.apiToken}`,
      }),
    };
    return this.apiService.post(environment.mainApiUrl, url, data, options);
  }

  login(data) {
    let url = `login`;
    const options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.apiToken}`,
      }),
    };
    return this.apiService.post(environment.mainApiUrl, url, data, options);
  }

  logout() {
    let url = `logout`;
    const options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.apiToken}`,
      }),
    };
    return this.apiService.post(environment.mainApiUrl, url, null, options);
  }
}
