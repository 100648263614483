import { environment } from "./../environments/environment";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler, APP_INITIALIZER } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./components/shared.module";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { Router } from "@angular/router";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { GlobalConstants } from "./common/global/global-constants";
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from "ng-recaptcha";
import { EnvServiceProvider } from "./services/env/env.service.provider";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import * as Sentry from "@sentry/angular-ivy";

if (environment.initSentry) {
  Sentry.init({
    dsn: "https://4437d1885d4c4e8e84f5c0661b9c6c7d@sentry.myeg.com.my/70",
    environment: environment.sentryEnvName,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Sentry.BrowserTracing({
        tracingOrigins: [environment.url],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment.sampleRate,
  });
}

@NgModule({
  declarations: [AppComponent, GlobalConstants],
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RecaptchaV3Module,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.defaultLanguage,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    EnvServiceProvider,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: window["__env"]["recaptchaV3SiteKey"],
    },
  ],
  exports: [TranslateModule],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(TraceModule: Sentry.TraceService) {}
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}
