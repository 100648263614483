const url = `https://verifyid.zetrix.com//`;

const mainApiUrl = `https://zcert.zetrix.com/`;
const pgwUrl = `https://pay.myeg.com.my/payment/v1/easy`;
const videoEnUrl = "https://www.youtube.com/embed/Xwy4o4q32wI";
const videoCnUrl = "../../assets/videos/CNVideo.mp4";
const twitterEnUrl = "https://twitter.com/zetrix_official";
const twitterCnUrl = "https://twitter.com/zetrixofficial";
const telegramEnUrl = "https://t.me/zetrixofficial";
const telegramCnUrl = "https://t.me/zetrixchinese";
const discordUrl = "https://discord.gg/bXaUG2p9";
const linkedlnUrl = "https://www.linkedin.com/company/zetrix/";
const facebookEnUrl = "https://www.facebook.com/zetrix.official";
const facebookCnUrl = "https://www.facebook.com/zetrixchinese";
const instagramUrl = "https://www.instagram.com/zetrixofficial/";
const xhsUrl = "https://www.xiaohongshu.com/user/profile/6476c89a000000001001ec04";
const tiktokEnUrl = "https://www.tiktok.com/@zetrix.official";
const tiktokCnUrl = "https://www.tiktok.com/@zetrixchinese";

export const environment = {
  production: true,
  uat: false,
  dev: false,
  url: url,
  routeUrl: mainApiUrl,
  pgwUrl: pgwUrl,
  mainApiUrl: `${mainApiUrl}api/`,
  sampleRate: 0.2,
  sentryEnvName: "Production",
  initSentry: true,
  zetrixChainId: "1", // Zetrix network that should be in. ChainId: 1 => Mainnet; ChainId: 2 => Test Network;
  zetrixBridge: "wss://wscw.zetrix.com",
  defaultLanguage: "en", // en | cn
  disableFeature: true, 
  landingEnVideo: videoEnUrl,
  landingCnVideo: videoCnUrl,
  twitterEnUrl: twitterEnUrl,
  twitterCnUrl: twitterCnUrl,
  telegramEnUrl: telegramEnUrl,
  telegramCnUrl: telegramCnUrl,
  discordUrl: discordUrl,
  linkedlnUrl: linkedlnUrl,
  facebookEnUrl: facebookEnUrl,
  facebookCnUrl: facebookCnUrl,
  instagramUrl: instagramUrl,
  xhsUrl: xhsUrl,
  tiktokEnUrl: tiktokEnUrl,
  tiktokCnUrl: tiktokCnUrl,
};
