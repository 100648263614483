import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ApiWalletService } from "./services/api/api-wallet.service";
import { WalletService } from "./services/wallet.service";

@Component({
  selector: "myeg-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "zcert-frontend";
  authChecked = false;

  constructor(
    private apiWalletService: ApiWalletService,
    private router: Router,
    private walletService: WalletService
  ) {}

  ngOnInit(): void {
    this.checkToken();
  }

  // eslint-disable-next-line
  checkToken() {
    const walletToken = sessionStorage.getItem("walletToken");
    if (walletToken) {
      // checkToken
      if (!this.authChecked) {
        this.apiWalletService.authCheck().subscribe(
          (response) => {
            if (response.status_code === "00") {
              if (response.data.status == true) {
                this.authChecked = response.data.status;
              } else {
                this.walletService.disconnectWallet();
              }
            }
          },
          (error) => {
            const errorCode = error.error.status_code;
            if (errorCode === "23" || errorCode === "25") {
              // Unauthenticated
              this.walletService.disconnectWallet();
            }
          }
        );
      } else {
        this.walletService.disconnectWallet();
      }
    } else {
      if (window.location.href.indexOf("/verify/email/confirmation/") == -1) {
        this.router.navigate([`/`]);
      }
    }
  }
}
