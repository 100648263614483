import { Injectable, Input } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { ApiWalletService } from "./api/api-wallet.service";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class WalletService {
  public static hasWalletAddress: Boolean = sessionStorage.getItem(
    "wallet-address"
  )
    ? true
    : false;
  activeModal: Boolean = false;

  constructor(
    private apiWalletService: ApiWalletService,
    private router: Router
  ) {}

  checkWalletStatus() {
    if (
      typeof window.astron == "undefined" &&
      typeof window.bifWallet == "undefined"
    ) {
      return false;
    } else {
      return true;
    }
  }

  // Connect Wallet Chrome Extension
  getWalletAccount() {
    return new Promise((resolve) => {
      setTimeout(() => {
        const walletSdk =
          typeof window.astron !== "undefined"
            ? window.astron
            : window.bifWallet;
        if (typeof walletSdk !== "undefined") {
          walletSdk.auth(async (result) => {
            if (result.code === 0) {
              this.apiWalletService
                .login({
                  accessToken: result.data.accessToken,
                  bid: result.data.bid,
                  publicKey: result.data.publicKey,
                })
                .subscribe(
                  (resp) => {
                    if (resp.status_code === "00") {
                      if (resp.data) {
                        // Login successful, save accessToken for walletScope
                        sessionStorage.setItem(
                          "wallet-address",
                          result.data.bid
                        );
                        sessionStorage.setItem("walletToken", resp.data);
                        return resolve(result);
                      }
                    }
                  },
                  (error) => {
                    return resolve(false);
                  }
                );
            } else if (result.code === -1 && result.data?.message) {
              resolve({ error: result.data.message });
            } else {
              return resolve(false);
            }
          });
        } else {
          return resolve(false);
        }
      }, 0);
    });
  }

  disconnectWallet() {
    this.apiWalletService.logout().subscribe(
      (response) => {
        if (response.status_code === "00") {
          window.location.href = "/";
          sessionStorage.clear();
          localStorage.clear();
        }
      },
      (error) => {
        window.location.href = "/";
        sessionStorage.clear();
        localStorage.clear();
      }
    );
  }

  listenNetwork() {
    return new Promise((resolve) => {
      setTimeout(() => {
        const astron = window.astron;
      }, 0);
    });
  }

  signMessage(bid, message) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const astron = window.astron;
        const bif = window.bifWallet;
        if (typeof window.astron !== "undefined") {
          astron.signMessage(
            {
              message: `${message}`,
            },
            async (result) => {
              if (result.code === 0) {
                const payload = {
                  bid: bid,
                  blob: message,
                  publicKey: result.data.publicKey,
                  signBlob: result.data.signData,
                };
                this.apiWalletService.login(payload).subscribe((response) => {
                  if (response.status_code === "00") {
                    sessionStorage.setItem("wallet-address", bid);
                    sessionStorage.setItem(
                      "walletInfo",
                      JSON.stringify(response.data)
                    );

                    return resolve(result);
                  }
                }),
                  (error) => {
                    if (error.error.errorCode === "23") {
                      this.router.navigate([`/home`]);
                      return resolve(false);
                    }
                  };
              } else {
                return resolve(false);
              }
            }
          );
        }
      }, 0);
    });
  }

  getVP(obj) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const astron = window.astron;

        if (typeof window.astron !== "undefined") {
          astron.getVP(obj, async (result) => {
            if (result.code === 0) {
              // signed
              resolve(result.data);
            } else if (result.code === 1) {
              // cancelled
              return resolve(false);
            }
          });
        } else {
          return resolve(false);
        }
      }, 0);
    });
  }

  toHex(str) {
    let result = "";
    for (let i = 0; i < str.length; i++) {
      result += str.charCodeAt(i).toString(16).padStart(2, 0);
    }
    return result;
  }

  @Input()
  get isMobile() {
    // detect mobile
    return /Mobi/.test(navigator.userAgent) ? true : false;
  }

  get isChrome() {
    // detect browser; NOTE: navigator.userAgent detecting microsoft edge as chrome
    return /Chrome/.test(navigator.userAgent) &&
      !/Edge|Edg|EdgiOS/.test(navigator.userAgent)
      ? true
      : false;
  }

  get isMobileDesktopSite() {
    // detect mobile that enabled Request Desktop Site
    return Math.min(window.screen.width, window.screen.height) < 768 &&
      !this.isMobile
      ? true
      : false;
  }
}

declare global {
  interface Window {
    astron: any;
    bifWallet: any;
  }
}
