import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) {}

  /**
   * Create a http get requested with a returned Observable.
   *
   * @param urlBase The URL base for the requested api.
   * @param url The url endpoint of the requested api.
   * @param options The http options for the requested api.
   */
  get(urlBase: string, url: string, options?: any): Observable<any> {
    return this.http.get(urlBase + url, options);
  }

  post(
    urlBase: string,
    url: string,
    body: any,
    options?: any
  ): Observable<any> {
    return this.http.post(urlBase + url, body, options);
  }

  put(urlBase: string, url: string, body: any): Observable<any> {
    return this.http.put(urlBase + url, body);
  }

  delete(urlBase: string, url: string, options?: any): Observable<any> {
    return this.http.delete(urlBase + url, options);
  }
}
