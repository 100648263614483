import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class EnvService {
  public recaptchaSiteKey = "";
  public apiToken =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiZWI1OGIwMzRiOWQyYTU0NjM5NmZmZmZlOGQ4NDYwMmU5MzdlMjE5MWM0OTA1N2VmMmEzNWRlN2RjN2QxNjcyOTJmMzU2ZGM5MDkyYjVjYTUiLCJpYXQiOjE2NzI4MjEzMzcuMzMwODY5LCJuYmYiOjE2NzI4MjEzMzcuMzMwODc5LCJleHAiOjE3MDQzNTczMzcuMzE4ODI0LCJzdWIiOiIzIiwic2NvcGVzIjpbImZyb250ZW5kIl19.oRQIdjQMCtdlHQ7MCArnoVpL_1ggCYiSfSLVd07_Ud8cpva5cWYSP0xglIbdKhM04EoiOnKUidzLXapP6gfBACrA5y6r8eJ4lVWgizBdsxyrePufODW8MQrVXT--8F0x4crrX9NE1_mKgMpm2-HDe-JeZ6_jOq0zoblRakWOckJnKxp2YFPtLw2ixCSAuSsjhLHS_WJvAjJT7KBmgsDKNb1LvmngqkCZ2i6xmhLCfe9NXAtGlGn0A1ZROEh17EsPcFpFVc1iU3fe3n7b01_RXCMhhKT0GlI1R1LyFgnVrUblGNTKcre-crDbTTP2Hqdf__R5-KhpSDc_WrIOXzp66ndahx6GzxHI7L1h8i7LTumAlKWoSxZ4qBS-yKy8TqMvhkinR3sJz8FxO6e5-V3ujq86wv8xvC8J8dsDCuCwN-57V9uXD351U8VA2nofnGYuW6RYrOhMu7eR-hgFRZQn9O_oIZVzK9MHczte6U8jdeDU546AIqepZcPpy7g7YhhisbA2uGzNjRxmyh_DTwK6uF2ZggTABUSTuOB3AJ0Fi5ZTK5FjacBBF-JLrL7wuXM3BbN2XOId8dahZVZHA3GN7Qf9u1wHLQfXkqRhPjel-HOYClik6FybwsLClTuIBSH5ccbZnByzISM6kDI3IGe6l1Eirk9nFY_rrG01QY5ibms";
  constructor() {}
}
