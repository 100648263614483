import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

import { RecaptchaComponent } from "ng-recaptcha";

RecaptchaComponent.prototype.ngOnDestroy = function() {
  // to fix recaptcha package -> zone.js: Unhandled Promise rejection
  if (this.subscription) {
    this.subscription.unsubscribe();
  }
};

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
