import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MatLegacySnackBarModule as MatSnackBarModule } from "@angular/material/legacy-snack-bar";

import { ModalComponent } from "./modal/modal.component";
import { ButtonComponent } from "./button/button.component";
import { MenuComponent } from "./menu/menu.component";
import { SelectComponent } from "./select/select.component";
import { TableComponent } from "./table/table.component";
import { PaginationComponent } from "./pagination/pagination.component";
import { UploadComponent } from "./upload/upload.component";
import { MessageComponent } from "./message/message.component";
import { ToastComponent } from "./toast/toast.component";
import { DatepickerComponent } from "./datepicker/datepicker.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [
    ModalComponent,
    ButtonComponent,
    MenuComponent,
    SelectComponent,
    TableComponent,
    PaginationComponent,
    UploadComponent,
    MessageComponent,
    ToastComponent,
    DatepickerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TranslateModule
  ],
  exports: [
    ModalComponent,
    ButtonComponent,
    MatSnackBarModule,
    MenuComponent,
    SelectComponent,
    TableComponent,
    PaginationComponent,
    UploadComponent,
    MessageComponent,
    ToastComponent,
    DatepickerComponent,
    TranslateModule
  ],
  providers: [MatDatepickerModule, MatNativeDateModule],
})
export class SharedModule {}
